
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LTooltip,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "Heatmaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LTooltip,
    LPopup,
  },
  setup() {
    setCurrentPageTitle("Laporan Pelanggaran");

    const store = useStore();
    // const router = useRouter();

    store.dispatch("AllViolationDashboard");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";

    const icon = path + "other/png/pelanggaran.png";

    // const markers = ref([
    //   {
    //     id: 1,
    //     position: [-6.30219, 106.725977],
    //     data: {
    //       name: "Oknum A",
    //       keterangan: "Politik Uang (serangan fajar di jalan A)",
    //       tanggal: "30 Agustus 2021",
    //       img: path + "other/png/Rectangle 1503.png",
    //       deskripsi: "Keterangan tambahannya ...",
    //       oleh: "Bayu",
    //     },
    //   },
    //   {
    //     id: 2,
    //     position: [-6.303169, 106.724987],
    //     data: {
    //       name: "Oknum B",
    //       keterangan: "Intimidasi terhadap pemilih",
    //       tanggal: "8 Januari 2021",
    //       img: path + "other/png/Rectangle -12.png",
    //       deskripsi: "Keterangan tambahannya ...",
    //       oleh: "Bayu",
    //     },
    //   },
    //   {
    //     id: 3,
    //     position: [-6.305169, 106.724987],
    //     data: {
    //       name: "Oknum C",
    //       keterangan: "Contoh C",
    //       tanggal: "9 September 2021",
    //       img: path + "other/png/Rectangle -1.png",
    //       deskripsi: "Keterangan tambahannya ...",
    //       oleh: "Bayu",
    //     },
    //   },
    // ]);

    const markers = computed(() => store.state.ViolationDashboardModule.all);

    const info = ref([
      {
        img: "other/png/pemuda.png",
        text: "Zona Pemuda",
      },
      {
        img: "other/png/pemuda-1.png",
        text: "Zona Orang Tua",
      },
      {
        img: "other/png/pemuda-2.png",
        text: "Zona Perempuan",
      },
    ]);

    return {
      center,
      markers,
      info,
      zoom,
      icon,
      iconSize,
    };
  },
});
